export const INVENTORIES = 'inventories';
export const INVENTORY = 'inventory';
export const BWICS = 'bwics';
export const BWIC = 'bwic';

class BondType {
  static getDefault(): string {
    return INVENTORIES;
  }

  static isInventories(data): boolean {
    return INVENTORIES === data;
  }

  static isBwics(data): boolean {
    return BWICS === data;
  }

  static isValid(bondType: string): boolean {
    return [
      INVENTORIES,
      BWICS,
    ].includes(bondType);
  }
}

export default BondType;
