import React from 'react';
import { Input, IconType } from '@dealroadshow/uikit';
import { IFinalFormFieldInputComponentProps } from '@/ui/shared/components/Form/FinalForm/interfaces';

interface IProps extends IFinalFormFieldInputComponentProps {
  className?: string,
  placeholder?: string,
  dataTest: string,
}

const EmailInput = ({ className, placeholder = 'Email', dataTest, ...props }: IProps) => (
  <Input
    type="email"
    name="email"
    placeholder={ placeholder }
    iconType={ IconType.email }
    className={ className }
    dataTest={ dataTest }
    { ...props }
  />
);

export default EmailInput;
