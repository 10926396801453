import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import throttle from 'lodash/throttle';
import { useRouter } from 'next/router';
import Breadcrumbs from '@/finsight/ui/common/components/header/Breadcrumbs';
import Header from '@/finsight/ui/common/components/header/Header';
import HeaderBanner from '@/ui/shared/components/Layout/HeaderBanner';
import { useHeaderMenuContext } from '@/finsight/ui/common/components/header/Header/HeaderMenuContext';
import { useScrollDirection } from 'src/Framework/hooks';
import StaticHeader from '@/finsight/ui/components/static/StaticPageHeader';
import useElementSize from '@/Framework/hooks/useElementSize';
import { staticPages } from '@/finsight/ui/components/static/StaticPageHeader/staticPages';
import { HEADER_WRAPPER_ID, STICKY_HEADER_CONTENT_ID } from '../constants';
import urlConstants from '@/finsight/Router/urlConstants.mjs';

import styles from './styles.scss';

const HeaderLayout = () => {
  const { pathname } = useRouter();
  // we can't use static page context here because we must know on the first render that this is static page or not.
  const isStaticPage = urlConstants.STATIC_PAGES_LIST.indexOf(pathname) !== -1;
  const [isFixed, setIsFixed] = useState(false);
  const {
    isMobileMenuOpen,
    isMarketDataMenuOpen,
    isSearchResultsOpen,
  } = useHeaderMenuContext();
  const scrollHandler = useCallback(throttle(() => {
    setIsFixed(window.scrollY > 0);
  }, 100), []);

  useEffect(() => {
    if (!isStaticPage) {
      window.addEventListener('scroll', scrollHandler);
    } else {
      setIsFixed(false);
    }
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [pathname]);

  const isOneOfHeaderMenuOpen = isMobileMenuOpen || isMarketDataMenuOpen || isSearchResultsOpen;
  const shouldMinimizeHeaderOnMobile = useScrollDirection();
  const { height: stickyContentHeight } = useElementSize(STICKY_HEADER_CONTENT_ID);
  return (
    <div
      className={ styles.headerWrapper }
      id={ HEADER_WRAPPER_ID }
    >
      <div className={ cn({
        [styles.menuOverlay]: isOneOfHeaderMenuOpen,
      }) }
      />
      <div
        className={
          cn(styles.headerLayoutContainer, {
            [styles.headerLayoutContainerWithMenu]: isOneOfHeaderMenuOpen,
            [styles.headerLayoutContainerWithMobileMenu]: isMobileMenuOpen,
            [styles.headerLayoutStaticContainer]: isStaticPage,
            [styles.headerLayoutFixedContainer]: isOneOfHeaderMenuOpen && isStaticPage,
          })
        }
      >
        <div className={ cn({
          [styles.menuWrapper]: isOneOfHeaderMenuOpen,
          [styles.marketDataMenuWrapper]: isMobileMenuOpen,
        }) }
        >
          { isFixed && <div style={ { height: stickyContentHeight } } /> }
          <div
            id={ STICKY_HEADER_CONTENT_ID }
            className={ cn(styles.stickyContent, {
              [styles.stickyContentFixed]: isFixed,
              [styles.stickyContentOpened]: isOneOfHeaderMenuOpen,
            }) }
          >
            <HeaderBanner isSticky={ false } />
            <Header
              hideSearch={ !isStaticPage && shouldMinimizeHeaderOnMobile }
              transparent={ isStaticPage && !isOneOfHeaderMenuOpen }
            />
            <Breadcrumbs
              wrapperClassName={ cn({
                [styles.hideBreadcrumbsWhenHeaderMenuOpened]: isOneOfHeaderMenuOpen,
                [styles.minimizeBreadcrumbsWhenMobileScroll]: !isStaticPage && shouldMinimizeHeaderOnMobile,
              }) }
            />
          </div>
        </div>
      </div>
      { isStaticPage && (
        <StaticHeader { ...staticPages[pathname] } />
      ) }
    </div>
  );
};

export default HeaderLayout;
