import HtmlResponseError from './HtmlResponseError';

interface RequestOptions {
  method?: string,
  headers?: HeadersInit,
  credentials?: RequestCredentials,
}

export default async (url: string, options: RequestOptions = {}): Promise<Response> => {
  if (!options.method) {
    options.method = 'GET';
  }
  if (!options.headers) {
    options.headers = { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', Pragma: 'no-cache' };
  }
  if (!options.credentials) {
    options.credentials = 'include';
  }

  let response = await fetch(url, options);
  let contentType = response.headers.get('Content-Type');

  if (
    contentType.indexOf('text/html') !== -1 ||
    contentType.indexOf('text/plain') !== -1
  ) {
    let body = await response.text();
    throw new HtmlResponseError(`API returns wrong Content-Type: ${ contentType }`, { response, body });
  }

  return response;
};
