import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDIContext } from '@/Framework/DI/DIContext';
import useRouter from '@/Framework/hooks/useNextRouter';

interface IProps {
  name?: string,
  to?: string,
  children?: React.ReactNode,
  className?: string,
  isNext: boolean,
}

const EXTERNAL_LINK = Symbol('ExternalLink');

const ExternalLink = ({ name = 'externalLink', to, children, className, isNext = true }: IProps) => {
  const { replace: nextReplace, asPath, query } = useRouter();
  const history = useHistory();

  const pathname = isNext ? asPath.split('?')[0] : history.location.pathname;
  const search = isNext ? query.q?.toString() : history.location.search;
  const replace = isNext ? nextReplace : history.replace;
  const isNewLocation = isNext ? query : history.location;

  const { container } = useDIContext();

  useEffect(() => {
    performExternalRedirect();
  }, [isNewLocation]);

  const initExternalRedirect = () => {
    container.bindValue(EXTERNAL_LINK, {
      initialPath: pathname + search,
      to,
    });
    replace('/external-link');
  };

  const performExternalRedirect = () => {
    if (
      pathname === '/external-link' &&
      (!container.findBestFactory(EXTERNAL_LINK) || !container.get(EXTERNAL_LINK))
    ) {
      window.location.href = '/';
      return;
    }

    if (pathname === '/external-link' && container.get(EXTERNAL_LINK)) {
      /*
      * In this place I patch default promise realization with one that prevents
      * it from resolving and rejecting to stop react rendering page while browser is navigating
      * to another url
      * */
      // @ts-ignore
      window.Promise = ((NativePromise) => {
        function CustomPromise(callback) {
          /**
           * @param {Function} resolve
           * @param {Function} reject
           */
          const customCallback = (resolve, reject) => {
            // eslint-disable-next-line @typescript-eslint/no-implied-eval
            resolve = () => { setTimeout(resolve, Infinity); };
            // eslint-disable-next-line @typescript-eslint/no-implied-eval
            reject = () => { setTimeout(reject, Infinity); };
            callback(resolve, reject);
          };

          return new NativePromise(customCallback);
        }

        CustomPromise.prototype = Object.create(NativePromise.prototype);
        CustomPromise.prototype.constructor = CustomPromise;

        CustomPromise.resolve = NativePromise.resolve.bind(CustomPromise);
        CustomPromise.reject = NativePromise.reject.bind(CustomPromise);
        CustomPromise.all = NativePromise.all.bind(CustomPromise);

        return CustomPromise;
      })(window.Promise);

      const { initialPath, to } = container.get(EXTERNAL_LINK);
      replace(initialPath);
      window.location.href = to;
    }
  };

  return (
    <a
      className={ className }
      onClick={ initExternalRedirect }
      data-test={ name }
    >
      { children }
    </a>
  );
};

export default ExternalLink;
