export default class HtmlResponseError {
  message: string;

  data: any;

  stack?: string;

  constructor(msg: string, data: any) {
    this.message = msg;
    this.data = data;
    this.stack = (new Error()).stack;
  }
}
