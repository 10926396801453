import { useEffect } from 'react';
import isIE from '@/Framework/browser/isIE';
import { useHeaderMenuContext } from '@/finsight/ui/common/components/header/Header/HeaderMenuContext';

/**
 * In IE11 in the marketDateMenu, children of the flex container do not get the maximum width.
 * This function triggers the recalculation of html grid in IE11.
 * @param ref
 */
const useFixWidthMarketDataMenuOnIE11 = (ref) => {
  if (!isIE()) {
    return;
  }
  /* eslint-disable react-hooks/rules-of-hooks */
  const { isMarketDataMenuOpen } = useHeaderMenuContext();
  useEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        ref.current.style.width = ref.current.style.width === '100%' ? 'auto' : '100%';
      }
    }, 0);
  }, [isMarketDataMenuOpen]);
};

export default useFixWidthMarketDataMenuOnIE11;
